// create supabase class

import { createClient } from "@supabase/supabase-js";

export default class SupabaseClient {
  constructor(data) {
    this.initSupabase();
  }

  async initSupabase() {
    const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
    const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
    this.defaultEmail = process.env.REACT_APP_SUPABASE_EMAIL;
    this.supabase = await createClient(supabaseUrl, supabaseKey);
    return this.supabase;
  }

  getDataFromTable = async (table) => {
    const { data, error } = await this.supabase.from(table).select();
    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };

  getGuests = async () => {
    return this.getDataFromTable("guests");
  };

  getGuestsByNameAndCode = async (code, name) => {
    console.log("Getting guests by name and code", name, code);
    if (this.supabase === undefined) {
      console.error("Supabase not initialized");
      return { data: [], error: "Supabase not initialized" };
    }

    if (!code || !name) {
      return { data: [], error: "Missing code or name" };
    }
    const { data, error } = await this.supabase.rpc("get_people_by_code", {
      given_code: code,
      last_name: name,
    });

    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };

  login = async (password) => {
    console.log("Logging in with", this.defaultEmail, password);
    const { data, error } = await this.supabase.auth.signInWithPassword({
      email: this.defaultEmail,
      password: password,
    });
    console.log(data, error);
    return { data, error };
  };
  getUser = async () => {
    const { data, error } = await this.supabase.auth.getUser();
    console.log("User", data.user, error);

    if (data.user) {
      return { data: data.user };
    } else {
      return { error: "error" };
    }
  };

  updateGuests = async (guest, rsvp_code) => {
    console.log("Updating guest", guest);
    // const { data, error } = await this.supabase.from("guests").upsert(guest);
    const { data, error } = await this.supabase.rpc("update_guest_info", {
      _id: guest.id,
      _rsvp_code: rsvp_code,
      _email: guest.email,
      _attending: guest.attending,
      _phonenumber: guest.phonenumber,
      _firstname: guest.firstname,
      _lastname: guest.lastname,
      _child: guest.child,
    });
    // const { data, error } = await this.supabase.from("guests").upsert(guest);
    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };
}
