import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Checkbox,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from "@mui/material/Grid2";
export default function Invitee(props) {
  const [invitee, setInvitee] = useState(props.invitee);
  const [index, setIndex] = useState(props.index);
  const setFormData = props.setFormData;
  const handleInviteeChange = (index, e) => {
    props.handleInviteeChange(index, e);
  };
  const deleteInvitee = (index) => {
    props.deleteInvitee(index);
  };
  const saveInvitee = (index) => {
    console.log("Save invitee", index);
    // props.saveInvitee(index);
  };

  useEffect(() => {
    console.log("Invitee", invitee);
    setInvitee(props.invitee);
  }, [props]);

  return (
    <Box
      key={index}
      // add a box around the invitee fields to add some margin
      sx={{
        border: "1px solid lightgray",
        borderRadius: "5px",
        padding: "12px",
        margin: "10px",
        position: "relative",
      }}
    >
      {/* <IconButton
        aria-label="delete"
        onClick={() => deleteInvitee(index)}
        style={{
          position: "absolute",
          right: -10,
          top: -10,
          color: "red",
          zIndex: 1,
          backgroundColor: "lightcoral",
        }}
      >
        <DeleteIcon style={{ color: "white" }} />
      </IconButton> */}
      <Grid
        container
        // direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
        spacing={1}
        key={index}
      >
        <Grid item size={{ xs: 12, md: 6 }}>
          <TextField
            label="Lastname"
            name="lastname"
            value={invitee.lastname}
            onChange={(e) => handleInviteeChange(index, e)}
            required
          />
        </Grid>
        <Grid item size={{ xs: 12, md: 6 }}>
          <TextField
            label="Firstname"
            name="firstname"
            value={invitee.firstname}
            onChange={(e) => handleInviteeChange(index, e)}
            required
          />
        </Grid>
        <Grid item size={{ xs: 12 }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="child"
              value={
                invitee.child === true
                  ? "child"
                  : invitee.child === false
                  ? "adult"
                  : ""
              }
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                console.log(e.target.value === "child");
                if (e.target.value === "child") {
                  // copy e object and change the value to true
                  const e_copy = {
                    ...e,
                    target: { ...e.target, value: true, name: e.target.name },
                  };
                  console.log("e", e);
                  console.log("e_copy", e_copy);
                  handleInviteeChange(index, e_copy);
                } else {
                  const e_copy = {
                    ...e,
                    target: { ...e.target, value: false, name: e.target.name },
                  };
                  handleInviteeChange(index, e_copy);
                }
              }}
            >
              <FormControlLabel
                value="adult"
                control={<Radio />}
                label="Adult"
              />
              <FormControlLabel
                value={"child"}
                control={<Radio />}
                label="Child"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          size={{ xs: 12 }}
          // set a background color for the attending checkbox
          style={{
            backgroundColor:
              invitee.attending === true
                ? "lightgreen"
                : invitee.attending === false
                ? "lightcoral"
                : "lightgray",
            // add rounded corner
            borderRadius: "5px",
          }}
        >
          <Button
            checked={invitee.attending || false}
            onClick={(e) => {
              const e_copy = {
                ...e,
                target: {
                  ...e.target,
                  value: !invitee.attending,
                  name: e.target.name,
                },
              };
              handleInviteeChange(index, e_copy);
            }}
            color="black"
            name="attending"
            inputProps={{ "aria-label": "controlled" }}
            style={{
              backgroundColor:
                invitee.attending === true
                  ? "lightgreen"
                  : invitee.attending === false
                  ? "lightcoral"
                  : "lightgray",
            }}
            fullWidth
            startIcon={
              invitee.attending === true ? (
                <CheckBoxIcon style={{ color: "green" }} />
              ) : invitee.attending === false ? (
                <CloseIcon style={{ color: "red" }} />
              ) : (
                ""
              )
            }
          >
            Attending {invitee.attending === null ? "?" : ""}
          </Button>
        </Grid>
        {/* <Grid item size={{ xs: 6 }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "auto" }}
            fullWidth
            onClick={() => {
              deleteInvitee(index);
            }}
          >
            <DeleteIcon />
          </Button>
        </Grid> */}
        {/* <Grid item size={{ xs: 6 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "auto" }}
            fullWidth
            onClick={() => {
              saveInvitee(index);
            }}
          >
            <SaveIcon />
          </Button>
        </Grid> */}
      </Grid>
    </Box>
  );
}
