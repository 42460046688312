import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

const initialPhotos = [
  { id: 1, title: "Photo 1", url: "https://picsum.photos/id/1/150" },
];

const Photos = () => {
  const [photos, setPhotos] = useState(initialPhotos);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const observer = useRef();

  const loadMorePhotos = useCallback(() => {
    setLoading(true);

    const newPhotos = Array.from({ length: 10 }, (_, index) => ({
      id: photos.length + index + 1,
      title: `Photo ${photos.length + index + 1}`,
      url: `https://picsum.photos/id/${photos.length + index + 11}/150`,
    }));
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    setPage((prevPage) => prevPage + 1);
    setLoading(false);
  }, [photos]);

  const lastPhotoElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMorePhotos();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadMorePhotos]
  );

  return (
    <div
      // minimize the padding on the side
      sx={{ padding: 0 }}
    >
      {/* <Typography variant="h4" gutterBottom>
        Photo Gallery
      </Typography> */}
      <Grid container spacing={0.5}>
        {photos.map((photo, index) => {
          if (index === photos.length - 1) {
            return (
              <Grid item key={photo.id} xs={4} sm={4} md={4} lg={3}>
                <Card sx={{ boxShadow: "none", borderRadius: 0 }}>
                  <CardMedia
                    ref={lastPhotoElementRef}
                    component="img"
                    // add source
                    alt={photo.title}
                    image={photo.url}
                    sx={{ width: "100%", height: "auto" }}
                  />
                </Card>
              </Grid>
            );
          } else {
            return (
              <Grid item key={photo.id} xs={4} sm={4} md={4} lg={3}>
                <Card sx={{ boxShadow: "none", borderRadius: 0 }}>
                  <CardMedia
                    component="img"
                    // add source
                    alt={photo.title}
                    image={photo.url}
                    sx={{ width: "100%", height: "auto" }}
                  />
                </Card>
              </Grid>
            );
          }
        })}
      </Grid>
      {/* {loading && ( */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <CircularProgress />
      </div>
      {/* )} */}
    </div>
  );
};

export default Photos;
