import React from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
const Rsvp = () => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate("/check-rsvp");
  };

  const handleCreate = () => {
    navigate("/create-rsvp");
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px", padding: "20px" }}>
      <h1>RSVP Page</h1>
      <p>Would you like to edit an existing RSVP or create a new one?</p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleEdit}
        fullWidth
        sx={{
          marginBottom: 1,
        }}
      >
        Edit RSVP
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        fullWidth
      >
        Create New RSVP
      </Button>
    </Container>
  );
};

export default Rsvp;
