import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
const pass = process.env.REACT_APP_LOCAL_PASSWORD;

export default function PassCheck(props) {
  const { supabase, onSuccess, onFailed } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  // const checkPassword = (inputPassword) => {
  //   console.log("Checking password...", inputPassword);
  //   if (pass === undefined || pass === "") {
  //     return;
  //   }
  //   if (inputPassword === pass) {
  //     props.setModal(null);
  //     props.setUnlocked(true);
  //     const epochTime = new Date().getTime();
  //     const expiryTime = epochTime + 24 * 60 * 60 * 1000;
  //     localStorage.setItem("user", expiryTime);
  //     if (props.onSuccess) {
  //       props.onSuccess();
  //     }
  //   } else {
  //     setPassword("");
  //     if (props.onFailed) {
  //       props.onFailed();
  //     }
  //   }
  // };

  const checkPassword = async (inputPassword) => {
    setLoading(true);
    if (pass === undefined || pass === "") {
      return;
    }
    const { data, error } = await supabase.login(inputPassword);
    if (error) {
      console.log("Error", error);
      setPassword("");
      if (onFailed) {
        onFailed();
      }
      return;
    }
    if (data) {
      console.log("Success", data);
      console.log("props", props);
      console.log("onSuccess", onSuccess);
      if (onSuccess) {
        const result = await onSuccess();
      }
    }
  };

  return (
    <div>
      {/* <form onSubmit={(e) => e.preventDefault()}> */}
      <Grid container spacing={0.5}>
        <Grid item xs={8}>
          <TextField // Add a text field for the password
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            autoFocus
            fullWidth
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                checkPassword(password);
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={1} // Add a button to submit the password
        >
          <LoadingButton
            fullWidth
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={() => checkPassword(password)}
            style={{ marginLeft: "10px", height: "100%" }}
          >
            <SendIcon />
          </LoadingButton>
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
}
