import React from "react";

export default function Blog() {
  return (
    <div>
      <h1>Blog Page</h1>
      {/* create a blog page  with lorem ipsum*/}
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, quod
        voluptates. Quisquam, quod. Quisquam, quod. Quisquam, quod. Quisquam,
      </p>
    </div>
  );
}
