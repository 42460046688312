import React, { useEffect } from "react";
import { TextField, Button, Grid, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Invitee from "../../components/Invitee";
export default function RsvpNew(props) {
  const [formData, setFormData] = useState({
    fistname: "",
    lastname: "",
    email: "",
    phone: "",
    guests: "",
    invitees: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    // get data from props if exist
    // if (props) {
    //   console.log("Props", props);
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     ...props,
    //   }));
    // }
    console.log("Props", props);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to send emails
    console.log("Form submitted", formData);
    // create a json with all the form information
    const data = {
      ...formData,
      guests: parseInt(formData.guests),
      kids: parseInt(formData.kids),
    };
    console.log("Data", data);
  };
  const handleInviteeChange = (index, e) => {
    const { name, value } = e.target;
    const newInvitees = [...formData.invitees];
    newInvitees[index] = {
      ...newInvitees[index],
      [name]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      invitees: newInvitees,
    }));
  };

  const addInvitee = () => {
    setFormData((prevData) => ({
      ...prevData,
      invitees: [
        ...prevData.invitees,
        { name: "", type: "adult" }, // Default values for new invitee
      ],
    }));
  };

  return (
    <>
      <div>
        <h1>RSVP Page</h1>
      </div>

      <Box
        style={{
          maxWidth: "500px",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          padding: "20px",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={() => navigate(-1)}
          sx={{ position: "absolute", top: "70px", left: "10px", mr: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              // justifyContent: "center",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            direction="row"
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                // fullWidth
                style={{
                  maxWidth: "250px",
                }}
                label="Lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                // fullWidth
                style={{
                  maxWidth: "250px",
                }}
                label="
                Firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                style={{ maxWidth: "250px" }}
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                style={{ maxWidth: "250px" }}
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
            {/* add button that adds invitee field in the form
             */}
            {formData.invitees.map((invitee, index) => (
              <Invitee
                key={index}
                invitee={invitee}
                index={index}
                handleInviteeChange={handleInviteeChange}
                deleteInvitee={(index) => {
                  const newInvitees = formData.invitees.filter(
                    (item, i) => i !== index
                  );
                  setFormData((prevData) => ({
                    ...prevData,
                    invitees: newInvitees,
                  }));
                }}
              />
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={addInvitee}
                startIcon={<AddIcon />}
              >
                Invitee
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
