import React from "react";
import { Container, Typography, Paper } from "@mui/material";

const Info = () => {
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Info Page
        </Typography>
        <Typography variant="body1" component="p">
          This is a simple info page using Material-UI for styling. You can add
          more content here as needed.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Info;
