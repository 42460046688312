import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Grid,
} from "@mui/material";

const schedule = [
  {
    time: "09:00",
    event: "Breakfast Meeting",
    description: "some extra info are found here",
  },
  {
    time: "11:00",
    event: "Team Standup",
    description: "some extra info are found here",
  },
  {
    time: "13:00",
    event: "Lunch Break",
    description: "some extra info are found here",
  },
  {
    time: "14:00",
    event: "Project Discussion",
    description: "some extra info are found here",
  },
  {
    time: "17:00",
    event: "Wrap Up",
    description: "some extra info are found here",
  },
];

const SchedulePage = () => {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Daily Schedule
      </Typography>
      <Paper elevation={3}>
        {schedule.map((item, index) => (
          <>
            <Divider key={index}>
              {item.time} {item.event}
            </Divider>
            <p>{item.description}</p>
          </>
        ))}
      </Paper>
    </Container>
  );
};

export default SchedulePage;
