import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Navbar from "./components/Navbar";
import RsvpNew from "./pages/rsvp/RsvpNew";
import Rsvp from "./pages/rsvp/Rsvp";
import RsvpCheck from "./pages/rsvp/RsvpCheck";
import Blog from "./pages/blog/Blog";
import Photos from "./pages/photos/Photos";
import Schedule from "./pages/schedule/Schedule";
import { Route, Routes } from "react-router-dom";
import Info from "./pages/info/Info";
import SupabaseClient from "./models/SupabaseClient";
import RsvpEdit from "./pages/rsvp/RsvpEdit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
const supabase = new SupabaseClient();

function App() {
  const toastId = React.useRef(null);

  const [unlocked, setUnlocked] = useState(true);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    verifyUSer();
  }, []);

  // on supabase init
  useEffect(() => {
    console.log("Supabase", supabase);
    // if (supabase.supabase) {
    //   const fetchGuests = async () => {
    //     const { data, error } = await supabase.getGuests();
    //     console.log("Data from table:", data);
    //   };
    //   fetchGuests();
    // }
    verifyUSer();
  }, [supabase]);

  const verifyUSer = async () => {
    // const user = localStorage.getItem("user");
    const { data, error } = await supabase.getUser();
    console.log("User", data);

    if (data) {
      console.log("User found and not expired");
      setUnlocked(true);
      setLoading(false);
      return true;
    }
    console.log("No user found");
    localStorage.removeItem("user");

    setUnlocked(false);
    setLoading(false);
    return false;
    // redirect to home
    // if (window.location.pathname !== "/") {
    //   window.location.href = "/";
    // }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      verifyUSer();
      console.log("Route changed");
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const setToast = (message, type, time = 1500) => {
    toastId.current = toast(message, {
      type: type,
      position: "bottom-right",
      autoClose: time,
      pauseOnFocusLoss: false,
    });
  };

  if (loading) {
    return (
      <div className="App">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer />
      <Modal
        open={!!modal}
        onClose={() => {
          if (
            unlocked ||
            window.location.pathname === "/" ||
            window.location.pathname === "/home"
          ) {
            setModal(null);
          }
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            minWidth: "200px",

            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          {modal}
        </div>
      </Modal>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Home
              supabase={supabase}
              unlocked={unlocked}
              setUnlocked={setUnlocked}
              setToast={setToast}
              setModal={setModal}
              verifyUSer={verifyUSer}
            />
          }
        />
        <Route
          path="/home"
          exact
          element={
            <Home
              supabase={supabase}
              unlocked={unlocked}
              setUnlocked={setUnlocked}
              setToast={setToast}
              setModal={setModal}
              verifyUSer={verifyUSer}
            />
          }
        />

        {/* <Route
              path="/check-rsvp"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Rsvp />
                  </PageWrapper>
                </>
              }
            /> */}
        <Route
          path="/create-rsvp"
          element={
            <>
              <PageWrapper>
                <Navbar />
                <RsvpNew />
              </PageWrapper>
            </>
          }
        />
        <Route
          path="/check-rsvp"
          element={
            <>
              <PageWrapper>
                <Navbar />
                <RsvpCheck supabase={supabase} setToast={setToast} />
              </PageWrapper>
            </>
          }
        />
        <Route
          path="/edit-rsvp"
          element={
            <>
              <PageWrapper>
                <Navbar />
                <RsvpEdit
                  supabase={supabase}
                  setToast={setToast}
                  setModal={setModal}
                  verifyUSer={verifyUSer}
                />
              </PageWrapper>
            </>
          }
        />
        {unlocked ? (
          <>
            <Route
              path="/blog"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Blog />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/photos"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Photos />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/schedule"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Schedule />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/info"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Info />
                  </PageWrapper>
                </>
              }
            />
            <Route path="*" element={<h1>Not Found</h1>} />
          </>
        ) : (
          <Route path="*" element={<h1>Not Found</h1>} />
        )}
      </Routes>
    </div>
  );
}

const PageWrapper = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <>
      <div
        style={{
          background: isHomePage ? "none" : "url(/img/homepage.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          position: "fixed",
          top: 0,
          left: 0,

          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          filter: "blur(5px)",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.85)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          position: "fixed",
          top: 0,
          left: 0,

          height: "100vh",
          width: "100vw",

          // blur the background
          // filter: "blur(10px)",
        }}
      ></div>
      <div
        style={{
          position: "relative",
          zIndex: 1,
          height: "100vh",
          width: "100vw",
          zIndex: 1,
        }}
      >
        {children}
      </div>
    </>
  );
};
export default App;
