import React, { useEffect } from "react";
import Invitee from "../../components/Invitee";
import { use } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IconButton, Button, Icon } from "@mui/material";
import { Box, maxWidth } from "@mui/system";
import Grid from "@mui/material/Grid2";

import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import YesNo from "../../components/yesNo";
import LoadingButton from "@mui/lab/LoadingButton";
import PassCheck from "../../components/PassCheck";
export default function RsvpEdit(props) {
  console.log("RsvpEdit", props);
  const supabase = props.supabase;
  const navigate = useNavigate();
  const [modal, setModal] = useState(null);
  const [initialInvitees, setInitialInvitees] = useState(null);
  const [toBeSaved, setToBeSaved] = useState(false);
  const [invitees, setInvitees] = useState(null);
  const [tempPhone, setTempPhone] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingNumber, setBookingNumber] = useState(null);
  useEffect(() => {
    console.log("Supabase", supabase);
    if (!supabase) {
      return;
    }
    // get parameters from url
    const urlParams = new URLSearchParams(window.location.search);
    const bookingNumber = urlParams.get("bookingNumber");
    setBookingNumber(bookingNumber);
    const lastName = urlParams.get("lastName");
    console.log("Booking Number", bookingNumber);
    console.log("Last Name", lastName);
    checkRsvp(bookingNumber, lastName);
  }, [supabase]);

  const checkRsvp = async (bookingNumber, lastName) => {
    console.log("Checking RSVP");
    const { data, error } = await supabase.getGuestsByNameAndCode(
      bookingNumber,
      lastName
    );
    if (error) {
      console.error("Error getting guests", error);
      return;
    }
    console.log("Data", data);
    if (data.length === 0) {
      console.error("No guests found");
      props.setModal(
        <PassCheck
          supabase={supabase}
          onSuccess={async () => {
            try {
              console.log("Checking on success");
              setLoading(true);
              const connected = await props.verifyUSer();
              if (connected) {
                props.setModal(null);

                checkRsvp(bookingNumber, lastName);
                // save to local storage
              }
              setLoading(false);
            } catch (error) {
              console.error("Error", error);
              setLoading(false);
            }
          }}
          onFailed={() => {
            props.setToast("Incorrect password", "error");
          }}
        />
      );
      return;
    }
    localStorage.setItem(
      "rsvp",
      JSON.stringify({
        bookingNumber: bookingNumber,
        lastName: lastName,
      })
    );
    const invitees = data;
    const initialInvitees = data.map((invitee) => ({ ...invitee }));
    setInvitees(invitees);
    setInitialInvitees(initialInvitees);
    setTempPhone(data[0].phonenumber);
    setTempEmail(data[0].email);
    console.log(data);
  };

  useEffect(() => {
    console.log("Invitees", invitees);
    console.log("Initial Invitees", initialInvitees);
    console.log(JSON.stringify(initialInvitees) !== JSON.stringify(invitees));
    setToBeSaved(JSON.stringify(initialInvitees) !== JSON.stringify(invitees));
  }, [invitees]);

  useEffect(() => {
    console.log("tempEmail", tempEmail);
    console.log("tempPhone", tempPhone);
  }, [tempEmail, tempPhone]);

  const handleInviteeChange = (index, e) => {
    console.log("handleInviteeChange", index, e.target);
    const { name, value } = e.target;
    console.log("Name", name);
    console.log("Value", value);
    const newInvitees = [...invitees];
    newInvitees[index][name] = value;
    setInvitees(newInvitees);
  };

  const deleteInvitee = (index) => {
    props.setModal(
      <YesNo
        title="Delete invitee"
        query={`Are you sure you want to delete ${invitees[index].firstname}?`}
        onYes={
          // reset then close modal
          () => {
            const newInvitees = [...invitees];
            newInvitees.splice(index, 1);
            setInvitees(newInvitees);
            setModal(null);
          }
        }
        onNo={() => setModal(null)}
      />
    );
  };

  const addInvitee = () => {
    const newInvitees = [...invitees];
    newInvitees.push({
      id: null,
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      attending: null,
      child: null,
    });
    setInvitees(newInvitees);
  };

  const saveInvitees = async () => {
    setLoading(true);
    // do a call for each invitee
    console.log("Invitees", invitees);
    invitees.forEach(async (invitee) => {
      invitee.phonenumber = tempPhone;
      invitee.email = tempEmail;
      console.log(tempEmail);
      const { data, error } = await supabase.updateGuests(
        invitee,
        bookingNumber
      );
      if (error) {
        console.error("Error updating guests", error);
        props.setToast("There was an issue", "error");

        setLoading(false);
        return;
      }
      console.log("Data", data);
    });
    props.setToast("Saved", "success");
    checkRsvp(bookingNumber, invitees[0].lastname);
    setLoading(false);
  };

  useEffect(() => {
    console.log("tempEmail", tempEmail);
    console.log("tempPhone", tempPhone);
  }, [tempEmail, tempPhone]);
  // if (!invitees) {
  //   return <CircularProgress />;
  // }

  return (
    <div // add blur if not unlockedor  no invitees
      style={{
        filter: invitees ? "none" : "blur(5px)",
      }}
    >
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          maxWidth: "90%",
          margin: "auto",
        }}
      >
        <div>
          <h1>RsvpEdit</h1>
        </div>
        <Grid
          container
          spacing={1}
          padding={2}
          // center content
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item size={{ xs: 12, sm: 4 }}>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="text"
              fullWidth
              disabled={!invitees}
              value={tempEmail}
              onChange={(e) => {
                e.preventDefault();
                setTempEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item size={{ xs: 12, sm: 4 }}>
            <TextField
              label="Phone"
              variant="outlined"
              margin="normal"
              type="text"
              fullWidth
              disabled={!invitees}
              value={tempPhone}
              onChange={(e) => setTempPhone(e.target.value)}
            />
          </Grid>
        </Grid>
        {invitees?.length > 0 ? (
          <Grid
            container
            spacing={2}
            // center content
            padding={2}
          >
            {invitees.map((invitee, index) => {
              return (
                <Grid item size={{ xs: 12, sm: 4 }} key={index}>
                  <Invitee
                    key={index}
                    invitee={invitee}
                    index={index}
                    handleInviteeChange={handleInviteeChange}
                    deleteInvitee={deleteInvitee}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <h2>No invitees</h2>
        )}
        {/* add invitee button */}

        <Grid
          container // center content
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          direction={"column"}
        >
          <Grid item size={{ xs: 12, sm: 4 }}>
            <Button
              onClick={addInvitee}
              startIcon={<AddIcon />}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "40px" }}
            >
              Add Invitee
            </Button>
          </Grid>
          <Grid item size={{ xs: 12, sm: 4 }}>
            <LoadingButton
              style={{ marginTop: "10px", display: "none" }}
              onClick={saveInvitees}
              startIcon={<SaveIcon />}
              variant="contained"
              fullWidth
              // style={{ marginTop: "10px" }}
              loading={loading}
              disabled={!toBeSaved}
            >
              Save
            </LoadingButton>
          </Grid>
          {toBeSaved && (
            <Grid item size={{ xs: 12, sm: 4 }}>
              <LoadingButton
                style={{
                  position: "fixed",
                  // bottom:
                  //   window.innerHeight > document.documentElement.clientHeight
                  //     ? "100px"
                  //     : "25px",
                  // // center
                  top: window.innerHeight - 50,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "90%",
                  transition: "bottom 0.3s",
                }}
                onClick={saveInvitees}
                startIcon={<SaveIcon />}
                variant="contained"
                fullWidth
                // style={{ marginTop: "10px" }}
                loading={loading}
                disabled={!toBeSaved}
              >
                Save
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}
